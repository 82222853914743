import React,{useState,useEffect} from 'react'
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
} from 'react-router-dom'
import axios from 'axios'
import { Userpilot } from 'userpilot'

import { useIdleTimer } from 'react-idle-timer'
import { WindowReload, WindowURL, WindowLocation, RouteURL } from '../services/redirect'

import Spinner from '../services/spinner'
import AlertMessage from '../services/alert'


/*---------------------------------------
 Include Header and Sidebar and Footer
---------------------------------------*/
import HeaderMenuComponent from '../components/header-menu'
import FooterComponent from '../components/footer'


/*---------------------------------------
 404 page
---------------------------------------*/
import ErrorPage from './ErrorPage/error'
import Redirect404 from './ErrorPage/redirect'


/*---------------------------------------
 Pages
---------------------------------------*/
import HomeComponent from './Home/home'
import ProductsComponent from './Products/product'
import CartComponent from './Cart/cart'
import CartHistoryTestDevComponent from './Cart/cart_history'

import OrdersComponent from './Orders/order'
import OrdersDetailsComponent from './Orders/details'
import ProductInquiryComponent from './Product-Inquiry/inquiry'
import ContactUsComponent from './ContactUs/contact'
import TestEmailComponent from './___TestEmail/___TestEmail'


Userpilot.initialize('NX-e987cecf');

export default function RootComponent(props){

  /*----------------------------------------
    SPINNER
  -----------------------------------------*/
  const [spinner,setspinner] = useState(true);
  const [contentspinner, setcontentspinner] = useState({});
  function SpinnerCallback(data){
    if(data.intent=='close'){
      setspinner(false);
      if(data.reload){
        WindowReload();
      }
    }
  }


  /*------------------------------------------------------
    ALERT MESSAGE
  -------------------------------------------------------*/
  const [notify,setnotify] = useState(true);
  const [nofitycontent, setnofitycontent] = useState({});
  function Notifycallback(data){
    if(data.intent=='close'){
      setnotify(false);
      if(data.reload){
        WindowReload();
      }
    }
  }
  
  /*function storageEventHandler(evt){
    console.log("oldValue: " + evt.oldValue );
    console.log("storage event called key: " + evt.key );
    console.log("newValue: " + evt.newValue );

    //console.log("old value" + evt.key.identifier_id);
    //console.log("new value" + evt.key.identifier_id);
    //WindowReload();

    /*if (evt.oldValue.email != evt.newValue) {
      WindowReload();
    }

  }*/

  /*------------------------------------------------------
    AUTH USER
  -------------------------------------------------------*/
  const [dataUser, setDataUser] = useState({});
  function AuthUser(){
      setcontentspinner({intent:'spinner',settings:{options:'white',centered:true,type:'basic',dimmer:'defualt',icon:'spinner',title:'Loading',text:""}});
      setspinner(true);
      let formData = new FormData();
      formData.append('type','auth');
      axios.post('/portal/auth/request.php',formData)
      .then(function (response) {
          let obj = response.data;

          if(obj==0){
            WindowLocation('/login');
            return false;
          }

          setDataUser({
            ...dataUser,
            id:obj.id,
            date_added:obj.date_added,
            account_number:obj.account_number,
            account_name:obj.account_name,
            short_name:obj.short_name,
            email:obj.email,
            hashId:obj.hashid,
            address_id:obj.address_id,
            account_type:obj.account_type,
            address_ctr:obj.address_ctr,
            cart_prescription:obj.cart_prescription
          });

          localStorage.setItem('identifier_id', obj.account_number);

          Userpilot.identify(obj.id,{
            name: obj.account_name,
            email: obj.email,
            created_at: obj.date_added,
          });

          var currency = {
            symbol: obj.currency.symbol,
            currency: obj.currency.currency,
            name:obj.currency.name
          }

          localStorage.removeItem('cart');
          if(obj.cart.length>0){
            localStorage.setItem('cart', JSON.stringify(obj.cart));
          }

          localStorage.setItem('currency', JSON.stringify(currency));
          localStorage.setItem('pricing', obj.pricing);


          setTimeout(function() {
            window.addEventListener("storage", () => {

              var identifier_id = JSON.parse(window.localStorage.getItem("identifier_id"));
              //console.log("Identifier ID: "+JSON.parse(window.localStorage.getItem("identifier_id")));
              if(identifier_id!=obj.account_number){
                WindowReload();
              }

            });
            setspinner(false);
          },1000);
    


      })
      .catch(function (error){
        setspinner(false);
        setnofitycontent({intent:'error',settings:{onclose:false,reload:false,title:'Server Error!',text:'Failed to connect through the server.'}});
        setnotify(true);
        setTimeout(function() {
          setnotify(false);
        },2000);
      });
 }


  /*------------------------------------------------------
    IDLE TIMER
  -------------------------------------------------------*/
  function UserAuthBehind(){
    let formData = new FormData();
    formData.append('type','auth');
    axios.post('/portal/auth/request.php',formData)
    .then(function (response) {
        let obj = response.data;
        if(obj==0){
          WindowLocation('/login');
          return false;
        }
    })
    .catch(function (error){
        return false;
    });
  }

  
  const onPrompt = () => {
    // Fire a Modal Prompt
  }

  const onIdle = () => {
    UserAuthBehind();
    // Close Modal Prompt
    // Do some idle action like log out your user
  }

  const onActive = (event) => {
    // Close Modal Prompt
    // Do some active action
  }

  const onAction = (event) => {
    // Do something when a user triggers a watched event
  }

  const {
    start,
    reset,
    pause,
    resume,
    isIdle,
    isPrompted,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime
  } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    onAction,
    timeout: 1000 * 60 * 5,
    promptTimeout: 0,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange'
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    syncTimers: 0
  });



  useState(()=>{

    AuthUser();

    Userpilot.reload();
    Userpilot.track("Event Name");
    // e.g. Clicked the purchase button
    Userpilot.track("Clicked purchase button");
    // e.g. Submitted a help ticket
    Userpilot.track("Submitted help ticket", {
      url: "/support",
      article: "installation"
    });
    

  },[]);

  return (
    <React.Fragment>

        {notify&&<AlertMessage data={nofitycontent} callback={Notifycallback}/>}
        {spinner&&<Spinner data={contentspinner} callback={SpinnerCallback}/>}

        {!spinner&&<React.Fragment>
          <HeaderMenuComponent data={dataUser}/>
          <div className="appWrapper-body">

            <Routes>
               <Route path="/" element={<HomeComponent data={dataUser}/>}/>
               <Route path="/cart" element={<CartComponent data={dataUser}/>}/>
               <Route path="/cart_history_test_dev" element={<CartHistoryTestDevComponent data={dataUser}/>}/>
               <Route path="/products" element={<ProductsComponent />}/>
               <Route path="/orders" element={<OrdersComponent />}/>
               <Route path="/order/details/:id" element={<OrdersDetailsComponent />}/>
               <Route path="/product-inquiry" element={<ProductInquiryComponent />}/>
               <Route path="/contact-us" element={<ContactUsComponent />}/>
               <Route path="/__test_email_page__" element={<TestEmailComponent />}/>
               

               {/* Page not found: 404 error */}
               <Route path="*" element={<Redirect404 />}/>
            </Routes>
          </div>
          <FooterComponent/>
        </React.Fragment>}

    </React.Fragment>
  )
}
